<template>
  <div class="toastification">
 
        <div style="padding-right:24px">
          <h4
            v-if="title"
            class="toastification-title"
            v-text="title"
            v-bind:style="[
            text==null
              ? { marginTop: '8px' }
              : {  },
          ]"
          />
          <small
            v-if="text"
            class="toastification-subtext"
            v-text="text"
          />
        </div>

  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.toastification-title {
  font-weight: 600;
    color: $navy;

}

.toastification-subtext {
    color: $navy;
    font-size:14px;
    margin-top: 0.5rem;
}

</style>
